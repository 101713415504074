import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import ArrowLeftSVG from '../assets/images/arrow_left.inline.svg'

const ArrowLeft = styled(ArrowLeftSVG)`
  overflow: visible;
  stroke: white;
  stroke-width: 2;
  stroke-miterlimit: 10;
`

const HR = styled.hr`
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #e6e67a;
  height: 1px;
`

const SuggestionBar = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: ${props => props.theme.colors.white.light};
  box-shadow: ${props => props.theme.shadow.suggestion};
`
const PostSuggestion = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 3rem 0 3rem;
`

const BlogWrapper = styled.section`
  display: grid;
  grid-template-columns: [xl-start] 1fr 1.5rem [md-start] minmax(0, 800px) [md-end] 1.5rem 1fr [xl-end];
  * {
    grid-column: md;
  }
`

const Post = ({ data, pageContext }) => {
  const { next, prev } = pageContext
  const post = data.markdownRemark
  const image = post.frontmatter.cover.childImageSharp.fluid
  const title = post.frontmatter.title
  const date = post.frontmatter.date
  const html = post.html

  console.log(post)
  return (
    <BlogWrapper className="markdown">
      <Link to="../">
        <ArrowLeft />
      </Link>
      <HR />
      <h3>{title}</h3>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </BlogWrapper>
  )
}

export default Post

Post.propTypes = {
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  }).isRequired,
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        date
        title
        tags
        cover {
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 90
              duotone: { highlight: "#386eee", shadow: "#2323be", opacity: 60 }
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`
